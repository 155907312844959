<template>
  <div>
    <b-card title="Sales Report">
      <b-row class="ml-auto">
        <b-col md="6">
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col md="6">
                  <div>
                    <b-form-group label="From Date" label-for="From Date">
                      <flat-pickr
                        name="dateField"
                        v-model="fromDate"
                        class="form-control"
                      />
                    </b-form-group>
                    <span>{{ errors }}</span>
                  </div>
                </b-col>
                <b-col md="6">
                  <div>
                    <b-form-group label="To Date" label-for="To Date">
                      <flat-pickr v-model="toDate" class="form-control" />
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <!-- filter -->
        <b-col md="3" class="my-2">
          <b-input-group-append>
            <b-button variant="primary" @click="fetchData"> Search </b-button>
          </b-input-group-append>
        </b-col>

        <b-col md="1" class="my-2">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              :variant="items.length > 0 ? 'primary' : 'secondary'"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>

              <b-dropdown-item
                v-if="items.length > 0"
                @click="exportExcelAction"
              >
                <feather-icon icon="DownloadIcon" /> Download
                Excel</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </b-col>

        <b-col md="12" variant="danger">
          <!-- handle error -->
          <b-alert
            v-height-fade.appear
            fade
            variant="danger"
            :show="isEmptyData"
          >
            <div class="alert-body">
              <ul>
                <li>There are no records to show for this range</li>
              </ul>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-table
        ref="admint"
        id="AdminsTable"
        striped
        sticky-header
        hover
        responsive
        show-empty
        :busy.sync="isBusy"
        class="position-relative"
        :items="items"
        :fields="fields"
        @row-clicked="transactionQuickView"
      >
        <!-- admin financialDetailsMarginPercentge -->
        <template #cell(financialDetailsMarginPercentge)="data">
          {{ data.value }} %
        </template>
        <!-- admin financialDetailsMarginPercentge -->
        <template #cell(netProfitNPPercentage)="data">
          {{ data.value }} %
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="AdminsTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
    <!-- handle error -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>

    <!-- Order Details in table for variant -->
    <b-modal
      id="transaction-quick-view-modal"
      hide-footer
      ref="modal"
      centered
      title="Transaction Details"
      size="xl"
    >
      <b-row v-if="transactionModal">
        <b-col md="12">
          <b-card>
            <p class="text-primary h5 mt-1">
              Transaction ID:
              <span class="h5">{{ transactionModal.id }}</span>
            </p>
            <p class="text-primary h5 mt-1">
              Transaction Ref:
              <span class="h5">
                <b-badge variant="light-info">
                  {{ transactionModal.transaction_ref }}
                </b-badge>
              </span>
            </p>
            <p class="text-primary h5 mt-1">
              Payment Gate:
              <b-badge variant="light-secondary">{{
                transactionModal.payment_gate
              }}</b-badge>
            </p>
            <p class="text-primary h5 mt-1">
              Payment Channel:
              <span class="h5">{{ transactionModal.payment_channel }}</span>
            </p>
            <p class="text-primary h5 mt-1">
              Bank Name:
              <b-badge variant="light-warning">{{
                transactionModal.bank_name
              }}</b-badge>
            </p>
          </b-card>
        </b-col>
        <b-col md="12">
          <b-card title="Transaction Amount Details">
            <b-row>
              <b-col md="6">
                <p class="text-secondary" style="margin-bottom: 5px">
                  <b-badge variant="light-secondary">Invoice Details</b-badge>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Total Price:
                  <span class="h5">{{
                    transactionModal.invoice_total_price
                  }}</span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Remaining Amount:
                  <span class="h5">{{
                    transactionModal.invoice_remaining_amount
                  }}</span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Seller Cost:
                  <span class="h5">
                    {{ transactionModal.invoice_seller_cost }}
                  </span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Shipping Collect:
                  <span class="h5">
                    {{ transactionModal.collect_shipping }}
                  </span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Purchase Fees:
                  <span class="h5">
                    {{ transactionModal.invoice_purchase_fees }}
                  </span>
                </p>
             
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Margin Egp:
                  <span class="h5">
                    {{ transactionModal.invoice_margin_egp }}
                  </span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Margin %:
                  <span class="h5">
                    {{ transactionModal.invoice_margin_percentage }} %
                  </span>
                </p>
              </b-col>
              <b-col md="6">
                <p class="text-secondary" style="margin-bottom: 5px">
                  <b-badge variant="light-secondary">COGS</b-badge>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Cogs Insurance Fees:
                  <span class="h5">
                    {{ transactionModal.invoice_cogs_insurance_fees }}
                  </span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Channel / Bank Fees:
                  <span class="h5">{{
                    transactionModal.invoice_cogs_channel_bank_fees
                  }}</span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Vat Channel:
                  <span class="h5">
                    {{ transactionModal.invoice_cogs_vat_chaneel }}
                  </span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Shipping Cost:
                  <span class="h5">
                    {{ transactionModal.invoice_cogs_shipping_cost }}
                  </span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Packing Cost:
                  <span class="h5">
                    {{ transactionModal.invoice_cogs_packing_cost }}
                  </span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Total Cost:
                  <span class="h5">
                    {{ transactionModal.invoice_cogs_total_cost }}
                  </span>
                </p>
                <hr />
                <p class="text-secondary" style="margin-bottom: 5px">
                  <b-badge variant="light-secondary">Gross Profit</b-badge>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice MM Commission:
                  <span class="h5">
                    {{ transactionModal.invoice_gross_profit_mm_commisson }}
                  </span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice Vat :
                  <span class="h5">
                    {{ transactionModal.invoice_gross_profit_vat }}
                  </span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice GP Amount:
                  <span class="h5">
                    {{ transactionModal.invoice_gross_profit_amount }}
                  </span>
                </p>
                <p class="text-secondary" style="margin-bottom: 5px">
                  Invoice NP %:
                  <span class="h5">
                    {{ transactionModal.invoice_gross_profit_percentage }}
                  </span>
                </p>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="my-2">
        <b-card title="Orders Details">
          <b-row v-for="(order, index) in transactionModal.orders" :key="index">
            <b-col md="6">
              <p style="color: #7468f0; margin-bottom: 5px" class="h5">
                code:
                <span class="h5">
                  <b-badge style="color: #ebeef0; background-color: #3c92d8">{{
                    order.code
                  }}</b-badge>
                </span>
              </p>
              <p style="color: #7468f0; margin-bottom: 5px" class="h5">
                status:
                <span class="h5">{{ order.status }}</span>
              </p>
              <p style="color: #7468f0; margin-bottom: 5px" class="h5">
                Username: <span class="h5">{{ order.customer_name }}</span>
              </p>
              <p style="color: #7468f0; margin-bottom: 5px" class="h5">
                seller:
                <span class="h5">{{ order.seller }}</span>
              </p>
            </b-col>
            <b-col md="6">
              <p style="color: #7468f0; margin-bottom: 5px" class="h5">
                settled_status:
                <span class="h5">{{ order.settled_status }}</span>
              </p>
              <p style="color: #7468f0; margin-bottom: 5px" class="h5">
                trx_type:
                <span class="h5">{{ order.trx_type }}</span>
              </p>
              <p style="color: #7468f0; margin-bottom: 5px" class="h5">
                AWB Number:
                <span class="h5">{{ order.air_way_bill_number }}</span>
              </p>
            </b-col>
            <b-col md="12">
              <p class="text-secondary h5 mt-1">Items</p>
              <table class="table table-striped table-responsive">
                <thead>
                  <tr>
                    <th scope="col" class="th-spaces-update">SKU</th>
                    <th scope="col" class="th-spaces-update">Name</th>
                    <th scope="col" class="th-spaces-update">Type</th>
                    <th scope="col" class="th-spaces-update">sales service</th>
                    <th scope="col" class="th-spaces-update">
                      PP For each Quantity
                    </th>
                    <th scope="col">Quantity</th>
                    <th scope="col" class="th-spaces-update">
                      Total Portal Price
                    </th>
                    <th scope="col" class="th-spaces-update">Seller Price</th>
                    <th scope="col">insurance fees</th>
                    <th scope="col">margin</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(variant, index) in order.variants" :key="index">
                    <td>{{ variant.sku }}</td>
                    <td class="th-spaces-update">
                      {{ variant.product_description }}
                    </td>
                    <td>
                      <b-badge variant="light-success">
                        {{ variant.is_new ? 'New' : 'Used' }}
                      </b-badge>
                    </td>
                    <td>{{ variant.sales_service }}</td>
                    <td>{{ variant.portal_price_for_each_quantity }}</td>
                    <td style="text-align: center">{{ variant.quantity }}</td>
                    <td>{{ variant.portal_price }}</td>
                    <td>{{ variant.seller_cost }}</td>
                    <td>{{ variant.insurance_fees }}</td>
                    <td>{{ variant.margin_price }}</td>
                    <td>
                      <b-button
                        style="
                          padding: 7px;
                          margin: 6px;
                          width: 35px;
                          height: 35px;
                        "
                        class="btn-icon rounded-circle"
                        :to="{
                          path:
                            (variant.is_new ? '/New' : '/Used') +
                            '/ShowProduct/variant/show/' +
                            variant.id,
                        }"
                        variant="success"
                      >
                        <feather-icon
                          style="width: 18px; height: 18px"
                          icon="EyeIcon"
                        />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col md="12" v-if="index + 1 != transactionModal.orders.length">
              <hr
                class="w-100 border-gray my-1"
                style="border-top: 5px solid #ebe9f1"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-modal>
  </div>
</template>
    
    <script>
import { MonthPickerInput } from 'vue-month-picker'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    MonthPickerInput,
    flatPickr,
  },
  data() {
    return {
      dateOptionByYear: [],
      errors: null,
      dateByYear: null,
      dateMonth: null,
      newMomentMonthDate: null,
      dateWeakly: null,
      dateDaily: null,
      totalsaleYear: null,
      fromDate: null,
      toDate: null,
      totalsaleQur: null,

      authPermissions: null,
      showDismissibleAlert: false,
      errors_back: [],
      perPage: 25,
      pageOptions: [25, 50, 100, 200, 300, 400, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      isBusy: false,
      sortBy: 'order_code',
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      btnclick: true,
      today: new Date(),
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // table fildes
      fields: [
        {
          key: 'id',
          label: 'id',
        },
        {
          key: 'transaction_ref',
          label: 'Transaction Ref',
          class: 'th-spaces-update',
        },
        {
          key: 'transaction_date',
          label: 'Transaction Date',
          class: 'th-spaces-update',
        },
        {
          key: 'payment_gate',
          label: 'Payment Gate',
          class: 'th-spaces-update',
        },
        {
          key: 'payment_channel',
          label: 'Payment Channel',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_total_price',
          label: 'Invoice Total Cost',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_remaining_amount',
          label: 'Invoice Remaining Cost',
          class: 'th-spaces-update',
        },
        {
          key: 'collect_shipping',
          label: 'Invoice Shipping Price',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_purchase_fees',
          label: 'Invoice Purchase Fees',
          class: 'th-spaces-update',
        },
        // {
        //   key: 'financialDetailsTotalPrice',
        //   label: 'Total Price',
        //   class: 'th-spaces-update',
        // },
        {
          key: 'invoice_seller_cost',
          label: 'Invoice Seller Cost',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_margin_egp',
          label: 'Invoice Margin EGP',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_margin_percentage',
          label: 'Invoice Margin %',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_cogs_insurance_fees',
          label: 'Invoice Cogs Insurance Fees',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_cogs_channel_bank_fees',
          label: 'Invoice COGS Channel Bank Fees',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_cogs_vat_chaneel',
          label: 'Invoice COGS Vat Chaneel',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_cogs_shipping_cost',
          label: 'Invoice COGS Shipping Cost',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_cogs_packing_cost',
          label: 'Invoice COGS Packing',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_cogs_total_cost',
          label: 'Invoice COGS Total Cost',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_gross_profit_mm_commisson',
          label: 'Invoice Gross Profit MM Commisson',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_gross_profit_vat',
          label: 'Invoice Gross Profit Vat',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_gross_profit_amount',
          label: 'Invoice Gross Profit NP Amount',
          class: 'th-spaces-update',
        },
        {
          key: 'invoice_gross_profit_percentage',
          label: 'Invoice Gross Profit NP %',
          class: 'th-spaces-update',
        },
      ],
      export: false,
      dailyDate: null,
      items: [],
      exportItems: [],
      transactionModal: {},
      isEmptyData: true,
    }
  },
  created() {
    this.dailyDate = `${this.today.getFullYear()}-${
      this.today.getMonth() + 1
    }-${this.today.getDate()}`
    this.fromDate = this.dailyDate
    this.toDate = this.dailyDate

    this.fetchData()
    this.authPermissions = this.$store.state.authPermissions
  },
  computed: {
    // sortable in table by add (sortable: true) in field of table fields
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    // compute row of pagination
    rows() {
      return this.totalRows
    },
  },
  methods: {
    transactionQuickView(item, index, event) {
      this.$bvModal.show('transaction-quick-view-modal')
      this.transactionModal = item
    },
    exportExcelAction() {
      const date =
        this.today.getFullYear() +
        '-' +
        (this.today.getMonth() + 1) +
        '-' +
        this.today.getDate() +
        '-' +
        this.today.getHours() +
        ':' +
        this.today.getMinutes() +
        ':' +
        this.today.getSeconds()

      const promise = axios.post(
        'reports/transactions/sales-report/export/data',
        this.sendData,
        { responseType: 'blob' }
      )
      return promise
        .then((result) => {
          console.log(result.data)
          const url = window.URL.createObjectURL(new Blob([result.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'SalesReport-' + date + '.xlsx')
          document.body.appendChild(link)
          link.click()

          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'downloaded success',
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // fetch data of admins
    fetchData() {
      this.isEmptyData = false
      this.sendData = {
        range: {
          // from: '2023-06-25',
          from: this.fromDate,
          to: this.toDate,
        },
      }
      this.showDismissibleAlert = false
      var url = null
      url =
        'reports/transactions/sales-report/' +
        this.perPage.toString() +
        '?page=' +
        this.currentPage

      const promise = axios.post(url, this.sendData)
      return promise
        .then((result) => {
          const data = result.data.data
          this.totalRows = data.meta.total

          this.items.length = 0

          this.items = data.data
          this.exportItems = this.items

          this.$refs.admint.refresh()

          if (this.items == 0) {
            this.isEmptyData = true
          }
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    // fetch data of sort result

    showDate(date) {
      this.dateMonth = date
    },
    changedType() {
      this.dateByYear = null
      this.dateMonth = null
      this.newMomentMonthDate = null
      this.dateWeakly = null
      this.dateDaily = null
      this.totalsaleQur = null
      this.totalsaleYear = null
      this.fromDate = null
      this.toDate = null
    },
    // fetch data of search result
    dateDiff() {
      const today = new Date()
      const oldday = new Date('1 January 2020')
      var ynew = today.getFullYear()
      var mnew = today.getMonth()
      var dnew = today.getDate()
      var yold = oldday.getFullYear()
      var mold = oldday.getMonth()
      var dold = oldday.getDate()
      var diff = ynew - yold
      if (mold > mnew) diff--
      else {
        if (mold == mnew) {
          if (dold > dnew) diff--
        }
      }
      this.dateByYear = new Date().getFullYear().toString()

      for (var i = 0; i <= diff; i++) {
        this.dateOptionByYear.push((parseInt(this.dateByYear) - i).toString())
      }

      return diff
    },
    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].avatar
      }
    },
    // print function
    printEle() {
      window.print()
    },
    // generate pdf function
    genPDF() {
      const { jsPDF } = require('jspdf')
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].name,
          this.items[index].email,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [['NAME', 'Email', 'CREATED_AT', 'UPDATED_AT']],
        body: print_body,
      })
      doc.save('table.pdf')
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
    
    <style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
    
    <style lang="css" >
.b-table-sticky-header {
  max-height: 92vh;
}
.th-spaces-update {
  white-space: nowrap;
  text-align: center;
  direction: ltr;
}
.month-picker__container,
.month-picker--default {
  background-color: #fff;
}
[dir] .month-picker-input[data-v-201d773d] {
  height: 38px !important;
  width: 100% !important;
}
.month-picker {
  font-weight: 200;
  box-sizing: border-box;
  flex: 1;
  width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: sans-serif;
  overflow: hidden;
  justify-content: space-evenly;
  align-content: space-around;
}
.dountRevenu {
  height: 75% !important;
}
.month-picker-input-container[data-v-201d773d] {
  width: 100% !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
    